body {
  margin: 0;
}

main {
  margin: 16px;
}

.navbar {
  display: flex;
  align-items: center;
  height: 50px !important;
  background: black;
  color: white;
  font-family: Helvetica;
  font-weight: 300;
}

.navbar__title {
  margin-right: auto;
  font-size: 150%;
  padding: 12px 16px;
}

.navbar__item {
  padding: 16px 25px;
  cursor: pointer;
  vertical-align: middle;
}
