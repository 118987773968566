.view-post-container{

//height: 100vh;
width:100%;
background-color: #eaebee;
display: flex;
flex-direction: column;

}

.card-container{

    height:80%;
    width:80%;
    background-color:#fff;
    align-self: center;
    justify-self: center;
    margin:10px;

}
.card_head-container{
    display:flex;
    flex-direction: row;
   // justify-content: center;
    padding:5%;

}
.content-container{

    display:flex;
    flex-direction: column;
    justify-content: center;
    padding:5%;
}