.h2, h2{
   // color: #734F46 !important;
    color: #000000 !important;

}

.navbar{
    background-color: rgba(255,255,255,0.8)!important;
    height: 50px;
    -webkit-backdrop-filter: blur(30) !important;
    backdrop-filter: blur(30px) !important;
    padding-left: 68px;
    padding-right: 40px;
    
}


.navbar-nav a{
    text-decoration: none !important;
}

.navLink, .nav-link {
  
    margin-left: 18px;
    margin-right: 18px;
    padding-left: 0;
    padding-right: 0;
    color: #734F46   !important;
    transition: ease-in-out 0.2s;
   
}

.navLink:hover, .nav-link:hover{
    color: #734F46 !important;
    transition: ease-in-out 0.2s;
    
}

.dropdown-item{
   
    color:#734F46 !important;
    transition: ease-in-out 0.1s;
}

.navbar-collapse{
    justify-content: flex-end;
    // background-color: white;
}

.navbar-collapse.show{
    background-color: rgba(255,255,255,0.95)!important;
    -webkit-backdrop-filter: blur(0px) !important;
    backdrop-filter: blur(10px) !important;
}

.navbar-toggler{
    width: 40px;
    height: 40px;
    margin-left: 36px;
    border-radius: 5px;
    display: inline-block;
    background-color: rgba(216, 63, 32, 0.1) !important;
    border-color: transparent !important;
    color: #734F46!important;
    justify-content: center;
    padding: 0 !important;
}

.navbar-toggler-icon{
    width: 20px !important;
    height: 20px !important;
}

@media only screen and (max-width: 1200px) {
    .navLink, .nav-link{
        margin: 0 10px 0 10px !important;
    }

    .dropdown-item{
        text-align: center !important;
        margin: 0 !important;
    }

}



.dropdown-item.active, .dropdown-item:active{
    background-color: #dddddd !important;
}

.dropdown-menu{
    background-color: rgba(255,255,255,0.95)!important;
    -webkit-backdrop-filter: blur(30) !important;
    backdrop-filter: blur(30px) !important;
    border: 0px solid #734F46 !important;
    box-shadow: 10px 10px 50px #734F46;
}