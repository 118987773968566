.main-head {
  padding: 30px;
}

.BANNER1 {
  background-image: url("./images/banner_back1.jpg") !important;
  background-size: cover;
  max-width: 100%;
  height: auto;
  color: white;
}

.banner-shade {
  background: rgb(115, 70, 78);
  background: linear-gradient(
    180deg,
    rgba(115, 70, 78, 0.7422677689239758) 2%,
    rgba(247, 247, 247, 0.20725376332173495) 100%
  );
}
.container {
  margin-top: 50px;
}

//button css
.button-one {
  border-radius: 4px;
  background: rgba(115, 79, 70, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border: none;
  color: #ffffff;
  text-align: center;
  height: 48px;
  font-size: 25px;
  padding: 5px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
}

.button-one span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button-one span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button-one:hover span {
  padding-right: 25px;
}

.button-one:hover span:after {
  opacity: 1;
  right: 0;
}
//user input styles
.user_input {
  width: 100%;
  background: rgba(242, 242, 242, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  height: 48px;
  color: #ffffff;
}
.user_input:focus {
  outline: none;
}

//text areaa
textarea {
  width: 100%;
  padding: 2px;
  resize: none;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(242, 242, 242, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-size: 1rem;
  color: white;
}

::placeholder {
  color: white;
  opacity: 1; /* Firefox */
  font-size: large;
}

textarea:focus {
  outline: none;
}

.input-label {
  font-size: 40px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.layer3-cover {
  height: 450px;
  opacity: 0.9;

  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);

  -webkit-backdrop-filter: blur(11px);

  border: 1px solid rgba(255, 255, 255, 0.18);
  font-size: 25px;
}
.row-details {
  height: 100%;
}
.t-area {
  padding: 24px 0 0 0 !important;
}

// background: rgba(115, 79, 70, 0.4);
//background-image:
//url("./images/manuscripts.jpg");
//background-repeat: no-repeat, no-repeat;
