.footer {
  padding: 1rem;
  background-color: white !important;

  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 20px;
  justify-content: space-between;
  opacity: 0.8;
}

.para {
  color: #734f46;
  font-size: 12px;
}

a {
  color: #2d1712 !important;
}
